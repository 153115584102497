import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';

import * as styles from './about.module.css';
const AboutPage = (props) => {
  let historyRef = useRef();
  let valuesRef = useRef();
  let sustainabilityRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom>
      <div className={styles.root}>
        {/* Hero Container */}
        <Hero
          maxWidth={'900px'}
          image={'/about.png'}
          title={`A story about Sunny`}
        />

        <div className={styles.navContainer}>
          <ThemeLink onClick={() => handleScroll(historyRef)} to={'#history'}>
            History
          </ThemeLink>
          <ThemeLink onClick={() => handleScroll(valuesRef)} to={'#values'}>
            Values
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(sustainabilityRef)}
            to={'#sustainability'}
          >
            Sustainability
          </ThemeLink>
        </div>

        <Container size={'large'} spacing={'min'}>
  <div className={styles.detailContainer} ref={historyRef}>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
      elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
      commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
      dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
    </p>
    <br />
    <br />
    <p>
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
      posuere cubilia Curae; Integer et nulla vel libero tincidunt dapibus
      eget id sem. Proin non urna in justo tincidunt placerat. Vestibulum
      nec elit ut nulla cursus ultricies vel id ligula.
    </p>
  </div>
</Container>

<div className={styles.imageContainer}>
  <img alt={'shirt brand'} src={'/about1.png'}></img>
</div>

<Container size={'large'} spacing={'min'}>
  <div className={styles.content}>
    <h3>Lorem Ipsum</h3>
    <div ref={valuesRef}>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
        elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
        commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
        dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
      </p>
      <ol>
        <li>Be an ecowear</li>
        <li>Sophisticated and not mass-produced</li>
        <li>Only natural materials</li>
      </ol>
      <img alt={'founder'} src={'/about2.png'}></img>
    </div>
    <h3>Lorem Ipsum</h3>
    <div id={'#sustainability'} ref={sustainabilityRef}>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
        elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
        commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
        dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
        elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
        commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
        dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
        elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
        commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
        dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
      </p>
    </div>
  </div>
</Container>


        <div className={styles.imageContainer}>
          <img alt={'shirt backwards'} src={'/about3.png'}></img>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
